import { useEffect } from "react";
import { Link } from "react-router-dom";
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button";

const Home = () => {
    useEffect(() => {
        document.title = "Ray Ganancial || Hello";
    }, []);

    return (
        <Fade in={true} style={{ transitionDelay: true ? "500ms" : "0ms" }}>
            <div className="home">
                <h2>Hello.</h2>
                <span className="intro">
                    My name is <strong>Ray Ganancial</strong>.
                </span>
                <div className="descriptors">
                    Husband / Father / .NET Developer / Front End Developer
                    / Dynamics 365 Developer
                </div>
                <div className="actions">
                    <Button
                        variant="outlined"
                        component={Link}
                        to="/resume"
                    >
                        View My Resume
                    </Button>
                </div>
            </div>
        </Fade>
    );
};

export default Home;
