import "./App.css";
import "./Animations.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Header from "./components/header"
import Slide from "@mui/material/Slide"
import Home from "./pages/home";
import Resume from "./pages/resume";
import Sandbox from "./pages/sandbox";
import { useState, useEffect } from "react";

const theme = createTheme({
    palette: {
        primary: {
            main: grey[300],
        },
    },
    select: {
        borderColor: grey[300]
    }
});

function App() {
    const color = "color-" + Math.floor(Math.random() * (5 - 1 + 1) + 1);
    const [showLogo, setShowLogo] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowLogo(true);
        }, 250);
    }, []);
    
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <div className="rg-app">
                    <Header />
                    <Slide
                        direction="down"
                        in={showLogo}
                        style={{ transitionDelay: showLogo ? "250ms" : "0ms" }}
                    >
                        <div id="logo" className={color}>
                            <div className="logo-inner">
                                <span className="name">rg</span>
                            </div>
                        </div>
                    </Slide>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/resume" element={<Resume />} />
                        <Route path="/sandbox" element={<Sandbox />} />
                    </Routes>
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;
