import React from 'react'

const PageHeader = (props) => {
    const { title = "Title", descriptor = "Page description"  } = props;
    return (
        <>
            <h2 className="title">{`${props.title}.`}</h2>
            <div className="descriptors">
                {props.descriptor}
            </div>
        </>
    );
}

export default PageHeader;
