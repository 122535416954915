import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { Padding } from "@mui/icons-material";
import { stringify } from "querystring";

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiInputLabel-root": {
        color: grey[300],
    },
    "&.Mui-focused .MuiInputLabel-outlined": {
        color: grey[300],
    },
    "& .MuiButtonBase-root": {
        color: grey[300],
    },
    "& .MuiAutocomplete-inputRoot": {
        color: grey[300],
        // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type':
            {
                // Default left padding is 6px
                paddingLeft: 6,
            },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: grey[300],
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: grey[300],
            backgroundColor: "rgba(100,100,100,.05)",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: grey[300],
            color: grey[300],
        },
    },
});

const Pokemon = () => {
    const defaultProps = {
        getOptionLabel: (option) => capitalize(option.name),
    };
    const [pokemon, setPokemon] = useState([]);
    const [selectedPokemonData, setSelectedPokemonData] = useState();
    const [loading, setLoading] = useState(true);

    function capitalize(word) {
        let words = word.split("-");
        let formatted = [];
        words.forEach((str) => {
            const firstLetter = str.charAt(0);
            const firstLetterCap = firstLetter.toUpperCase();
            const remainingLetters = str.slice(1);
            formatted.push(firstLetterCap + remainingLetters);
        });
        return formatted.join("-");
    }

    const onPokemonChange = (event, value) => {
        console.log(event);
        console.log(value);
        if (value) {
            fetch(value.url)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    window.pokemon = data;
                    setSelectedPokemonData({
                        id: data.id,
                        name: data.name,
                        sprite: data.sprites.front_default,
                        height: data.height,
                        weight: data.weight,
                        types: [...data.types]
                    });
                });
        } else {
            setSelectedPokemonData(null);
        }
    };

    const formatWeight = (weight) => {
        const conversion = 0.2204622622;
        const converted = Math.floor(weight * conversion);

        return `${converted} lbs`;
    };

    const formatHeight = (height) => {
        const conversion = 3.048;
        const converted = height / conversion;

        const feet = Math.floor(converted);
        const inches = Math.round((converted - feet) * 12);

        return `${feet}' ${inches}"`;
    };

    useEffect(() => {
        if (localStorage.pokemon) {
            console.log("pokemon from localStorage");
            setPokemon(JSON.parse(localStorage.pokemon));
        } else {
            console.log("pokemon from fetch");
            fetch("https://pokeapi.co/api/v2/pokemon?limit=100000&offset=0")
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    localStorage.pokemon = JSON.stringify(data.results);
                    setPokemon(data.results);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    useEffect(() => {
        if (pokemon) {
            console.log(pokemon);
        }
    }, [pokemon]);

    return (
        <div className="rg-pokemon">
            {pokemon ? (
                <div>
                    <h3>Poke API Sandbox</h3>
                    <StyledAutocomplete
                        {...defaultProps}
                        onChange={onPokemonChange}
                        disablePortal
                        autoComplete={true}
                        autoSelect={true}
                        id="pokemon-select"
                        options={pokemon}
                        renderInput={(params) => (
                            <TextField {...params} label="Pokemon" />
                        )}
                    />
                    <Card
                        variant="outlined"
                        sx={{ p: 2, mt: 2, textAlign: "center" }}
                    >
                        {selectedPokemonData ? (
                            <>
                                <p>
                                    <strong>
                                        {capitalize(selectedPokemonData.name)}
                                    </strong>
                                </p>
                                <img src={selectedPokemonData.sprite} />
                                <p>
                                    <strong>Type: </strong>{" "}
                                    {selectedPokemonData.types
                                        .map((type) => (
                                            <span key={type.slot}>
                                                {capitalize(type.type.name)}
                                            </span>
                                        ))
                                        .reduce((prev, curr) => [
                                            prev,
                                            "/",
                                            curr,
                                        ])
                                    }
                                </p>
                                <p>
                                    <strong>Height: </strong>{" "}
                                    {formatHeight(selectedPokemonData.height)}
                                </p>
                                <p>
                                    <strong>Weight: </strong>{" "}
                                    {formatWeight(selectedPokemonData.weight)}
                                </p>
                            </>
                        ) : (
                            <>Select a Pokemon</>
                        )}
                    </Card>
                </div>
            ) : (
                <div>Getting Pokemon data...</div>
            )}
        </div>
    );
};

export default Pokemon;
