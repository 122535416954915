import { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import Fade from "@mui/material/Fade";
import PageHeader from "../components/pageHeader";

const Resume = () => {
    const [skills, setSkills] = useState([]);
    const [workExperiences, setWorkExperiences] = useState([]);
    const [education, setEducation] = useState([]);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const emailClicked = () => {
        window.location.href = `mailto:${email}?subject=Email%20from%20Website:%20rayganancial.com&body=`;
    };

    useEffect(() => {
		document.title = "Ray Ganancial || Resume";
		
        const getDetails = async () => {
            const data = await getDocs(collection(db, "personalDetails"));
            const _name = data.docs.filter((doc) => {
                return doc.data().type === "name";
            });
            const _email = data.docs.filter((doc) => {
                return doc.data().type === "email";
            });
            setName(_name[0].data().displayText);
            setEmail(_email[0].data().displayText);
        };
        getDetails();

        const getSkills = async () => {
            const data = await getDocs(collection(db, "skills"));
            setSkills(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getSkills();

        const getWorkExperiences = async () => {
            const data = await getDocs(collection(db, "workExperience"));
            setWorkExperiences(
                data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            );
        };
        getWorkExperiences();

        const getEducation = async () => {
            const data = await getDocs(collection(db, "education"));
            setEducation(
                data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            );
        };
        getEducation();

        return () => {
            
        };
    }, []);

	return (
        <Fade in={true} style={{ transitionDelay: true ? "500ms" : "0ms" }}>
            <div>
                <div className="resume inner">
                    <PageHeader
                        title="Resume"
                        descriptor="Where I've worked / Where I've learned / What I've
                        learned"
                    />
                    <div id="details-container" className="resume-section">
                        <div className="resume-section-title">
                            <h2>details</h2>
                        </div>
                        <div className="resume-section-content">
                            {name && email ? (
                                <div className="detail slideRight">
                                    <strong>{name}</strong>
                                    <span
                                        className="email"
                                        onClick={emailClicked}
                                    >
                                        {email}
                                    </span>
                                </div>
                            ) : (
                                <div className="loading-container">
                                    <div className="loading half"></div>
                                    <div className="loading full"></div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div id="work-container" className="resume-section">
                        <div className="resume-section-title">
                            <h2>Work</h2>
                        </div>
                        <div className="resume-section-content">
                            {workExperiences.length ? (
                                workExperiences
                                    .sort((a, b) =>
                                        a.displayOrder < b.displayOrder ? 1 : -1
                                    )
                                    .map((work) => (
                                        <div
                                            key={work.id}
                                            className="work slideRight"
                                        >
                                            <strong>{work.name}</strong>
                                            <em>
                                                {work.startDate} to{" "}
                                                {work.endDate}
                                            </em>
                                            <p>{work.title}</p>
                                        </div>
                                    ))
                            ) : (
                                <div className="loading-container">
                                    <div className="loading full"></div>
                                    <div className="loading half short"></div>
                                    <div className="loading full"></div>
                                    <br />
                                    <div className="loading full"></div>
                                    <div className="loading half short"></div>
                                    <div className="loading full"></div>
                                    <br />
                                    <div className="loading full"></div>
                                    <div className="loading half short"></div>
                                    <div className="loading full"></div>
                                    <br />
                                    <div className="loading full"></div>
                                    <div className="loading half short"></div>
                                    <div className="loading full"></div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div id="edu-container" className="resume-section">
                        <div className="resume-section-title">
                            <h2>Edu</h2>
                        </div>
                        <div className="resume-section-content">
                            {education.length ? (
                                education.map((edu) => (
                                    <div
                                        key={edu.id}
                                        className="edu slideRight"
                                    >
                                        <strong>{edu.name}</strong>
                                        <em>
                                            {edu.degree} - {edu.graduationYear}
                                        </em>
                                        <p>{edu.field}</p>
                                    </div>
                                ))
                            ) : (
                                <div className="loading-container">
                                    <div className="loading full"></div>
                                    <div className="loading half short"></div>
                                    <div className="loading full"></div>
                                    <br />
                                </div>
                            )}
                        </div>
                    </div>
                    <div id="skills-container" className="resume-section">
                        <div className="resume-section-title">
                            <h2>Skills</h2>
                        </div>
                        <div className="resume-section-content">
                            {skills.length ? (
                                skills
                                    .sort((a, b) =>
                                        a.proficiency < b.proficiency ? 1 : -1
                                    )
                                    .map((skill) => (
                                        <div
                                            key={skill.id}
                                            className="skill slideRight"
                                        >
                                            {skill.name}
                                        </div>
                                    ))
                            ) : (
                                <div className="loading-container">
                                    <div className="loading full"></div>
                                    <div className="loading half"></div>
                                    <div className="loading full"></div>
                                    <div className="loading half"></div>
                                    <div className="loading full"></div>
                                    <div className="loading half"></div>
                                    <div className="loading full"></div>
                                    <div className="loading half"></div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

export default Resume;
