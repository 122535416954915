import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <>
            <header id="header">
                <h1>
                    <Link to="/" title="Home">
                        Ray Ganancial
                    </Link>
                </h1>
                <nav id="nav">
                    <ul>
                        <li>
                            <Link to="/resume" title="Resume">
                                Resume
                            </Link>
                        </li>
                        <li>
                            <Link to="/sandbox" title="Sandbox">
                                Sandbox
                            </Link>
                        </li>
                    </ul>
                </nav>
            </header>
        </>
    );
};

export default Header;
