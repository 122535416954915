import { useEffect } from "react";
import { grey } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PageHeader from "../components/pageHeader"
import Fade from "@mui/material/Fade";
import Pokemon from "../components/pokemon";

const theme = createTheme({
    palette: {
        primary: {
            main: grey[300],
        },
    },
});

const Sandbox = () => {
    useEffect(() => {
        document.title = "Ray Ganancial || Fun Stuff";
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Fade in={true} style={{ transitionDelay: true ? "500ms" : "0ms" }}>
                <div className="fun inner">
                    <PageHeader
                        title="Sandbox"
                        descriptor="Just some fun, random things that I am playing around
                        with."
                    />
                    <Pokemon />
                </div>
            </Fade>
        </ThemeProvider>
    );
};

export default Sandbox;
